//* **** Import Specific *****/
import { getjQuery, onDOMContentLoaded } from './mdb/mdb/util/index';

// BOOTSTRAP CORE COMPONENTS
import Button from './mdb/free/button';
import Collapse from './mdb/bootstrap/mdb-prefix/collapse';
import Offcanvas from './mdb/bootstrap/mdb-prefix/offcanvas';
import Carousel from './mdb/free/carousel';
// import Popover from './mdb/free/popover';
// import ScrollSpy from './mdb/free/scrollspy';
import Tab from './mdb/free/tab';
// import Tooltip from './mdb/free/tooltip';

// // MDB FREE COMPONENTS
// import Input from './mdb/free/input';
// import Dropdown from './mdb/free/dropdown';
// import Ripple from './mdb/free/ripple';
// import Range from './mdb/free/range';

// // MDB PRO COMPONENTS
// import Animate from './mdb/pro/animate';
// import Chart from './mdb/pro/charts';
import Lightbox from './mdb/pro/lightbox';
// import Rating from './mdb/pro/rating';
import Sidenav from './mdb/pro/sidenav';
// import Alert from './mdb/pro/alert';
// import Toast from './mdb/pro/toast';
// import Timepicker from './mdb/pro/timepicker';
import Navbar from './mdb/pro/navbar';
// import InfiniteScroll from './mdb/pro/infinite-scroll';
import LazyLoad from './mdb/pro/lazy-load';
// import Datepicker from './mdb/pro/datepicker';
// import Popconfirm from './mdb/pro/popconfirm';
// import Datatable from './mdb/pro/datatable';
// import Stepper from './mdb/pro/stepper';
// import Sticky from './mdb/pro/sticky';
// import Select from './mdb/pro/select';
// import Touch from './mdb/pro/touch';
import SmoothScroll from './mdb/pro/smooth-scroll';
// import PerfectScrollbar from './mdb/pro/perfect-scrollbar';
// import Loading from './mdb/pro/loading-management';
// import Autocomplete from './mdb/pro/autocomplete';
// import Modal from './mdb/pro/modal';
// import Clipboard from './mdb/pro/clipboard';
// import ChipsInput from './mdb/pro/chips';
// import MultiRangeSlider from './mdb/pro/multi-range';
// import Datetimepicker from './mdb/pro/date-time-picker';

onDOMContentLoaded(() => {
  const $ = (typeof jQuery === 'undefined') ? getjQuery() : jQuery;
  if ($) {

    // your scripts start here

  }
});


// use below if using this file as export
// export {
//   // FREE
//   Button,
//   Carousel,
//   Collapse,
//   Offcanvas,
//   Dropdown,
//   Input,
//   Modal,
//   Popover,
//   ScrollSpy,
//   Ripple,
//   Tab,
//   Tooltip,
//   Range,
//   // PRO
//   Alert,
//   Animate,
//   Chart,
//   Datepicker,
//   Datatable,
//   Lightbox,
//   Navbar,
//   Popconfirm,
//   Rating,
//   Sidenav,
//   SmoothScroll,
//   Timepicker,
//   Toast,
//   InfiniteScroll,
//   LazyLoad,
//   Stepper,
//   Sticky,
//   Select,
//   Touch,
//   PerfectScrollbar,
//   Loading,
//   Autocomplete,
//   Clipboard,
//   ChipsInput,
//   MultiRangeSlider,
//   Datetimepicker,
// };
